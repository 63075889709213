<template>
  <DxSelectBox
    v-model="selected"
    :items="options"
    :on-item-click="onChange"
    placeholder="Select Period"
    display-expr="text"
    value-expr="id"
  />
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box'

const commonPeriod = [
  {
    id: 'last7days',
    text: 'Last 7 Days',
    singleDay: false,
  },
  {
    id: 'thisMonth',
    text: 'This Month',
    singleDay: false,
  },
  {
    id: 'lastMonth',
    text: 'Last Month',
    singleDay: false,
  },
  {
    id: 'last30days',
    text: 'Last 30 Days',
    singleDay: false,
  },
  {
    id: 'last6months',
    text: 'Last 6 Months',
    singleDay: false,
  },
  {
    id: 'last12months',
    text: 'Last 12 Months',
    singleDay: false,
  },
  {
    id: 'lastYear',
    text: 'Last Year',
    singleDay: false,
  },
  {
    id: 'yearToDate',
    text: 'Year To Date',
    singleDay: false,
  },
  {
    id: 'allTime',
    text: 'All Time',
    singleDay: false,
  },
]

const defaultPeriods = [
  {
    id: 'today',
    text: 'Today',
    singleDay: true,
  },
  {
    id: 'yesterday',
    text: 'Yesterday',
    singleDay: true,
  },
  {
    id: 'dayBeforeYesterday',
    text: 'Day Before Yesterday',
    singleDay: true,
  },
  ...commonPeriod,
]

export default {
  name: 'DatePeriod',
  components: {
    DxSelectBox,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    disableSingleDayPeriod: {
      type: Boolean,
      default: false,
    },
    periodOptions: {
      type: Array,
      default: () => ([]),
    },
    allRanges: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: null,
      periods: [],
    }
  },
  computed: {
    options() {
      if (this.disableSingleDayPeriod) {
        return this.periods.filter(period => period.singleDay === false)
      }
      return this.periods
    },
  },
  mounted() {
    this.selected = this.value
  },
  created() {
    if (this.periodOptions.length) {
      this.periods = this.periodOptions
    } else if (this.allRanges) {
      this.periods = defaultPeriods
    } else {
      this.periods = commonPeriod
    }
  },
  methods: {
    onChange(data) {
      this.$emit('input', data.itemData.id)
      this.$emit('update', data.itemData.id)
    },
  },
}
</script>

<style scoped>

</style>
