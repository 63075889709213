<template>
  <div class="flex flex-column justify-content-center align-items-center">
    <div class="d-flex mb-1">
      <DxSelectBox
        :value="region2"
        :items="regionOptions"
        display-expr="title"
        value-expr="id"
        :disabled="isMarketplaceAvailable"
        @value-changed="onRegionChanged"
      />
    </div>
    <div
      v-if="!isRegionConnected"
      class="flex flex-column"
    >
      <div class="d-flex mb-50">
        <img
          style="cursor: pointer"
          :src="require(`@/assets/images/pages/amazon_connect/${region.id}.png`)"
          @click.stop="onConnectClick"
        >
      </div>
      <div class="flex flex-row flex-gap">
        <country-flag
          v-for="flag in regionFlags"
          :key="flag.countryIsoCode"
          :country="flag.countryIsoCode"
          :title="flag.country"
          :alt="flag.country"
          size="normal"
        />
      </div>
    </div>
    <div
      v-else
      class="flex flex-column text-success justify-content-center align-items-center"
      style="max-width: 350px"
    >
      <div class="text-center">
        <feather-icon
          icon="CheckCircleIcon"
          size="21"
        />
      </div>
      <div class="mt-50">
        This Marketplace is now successfully connected.
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { DxSelectBox } from 'devextreme-vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'ShopConnection',
  components: {
    DxSelectBox,
    CountryFlag,
  },
  data() {
    return {
      isReloaded: false,
      regionOptions: [
        {
          id: 'na',
          title: 'America',
        },
        {
          id: 'eu',
          title: 'Europe',
        },
        {
          id: 'jp',
          title: 'Asia',
        },
        {
          id: 'au',
          title: 'Australia',
        },
        {
          id: 'other',
          title: 'Other',
        },
      ],
      region: {
        id: 'na',
        title: 'America',
      },
      region2: 'na',
      sellerCentralURLs: [
        {
          url: 'https://sellercentral.amazon.com',
          region: 'na',
        },
        {
          url: 'https://sellercentral.amazon.co.uk',
          region: 'eu',
        },
        {
          url: 'https://sellercentral.amazon.jp',
          region: 'jp',
        },
        {
          url: 'https://sellercentral.amazon.au',
          region: 'au',
        },
        {
          url: 'https://sellercentral.amazon.in',
          region: 'other',
        },
      ],
      flags: [
        {
          countryIsoCode: 'usa',
          country: 'United States',
          region: 'na',
        },
        {
          countryIsoCode: 'ca',
          country: 'Canada',
          region: 'na',
        },
        {
          countryIsoCode: 'mx',
          country: 'Mexico',
          region: 'na',
        },
        {
          countryIsoCode: 'br',
          country: 'Brazil',
          region: 'na',
        },
        {
          countryIsoCode: 'gb',
          country: 'United Kingdom',
          region: 'eu',
        },
        {
          countryIsoCode: 'de',
          country: 'Germany',
          region: 'eu',
        },
        {
          countryIsoCode: 'fr',
          country: 'France',
          region: 'eu',
        },
        {
          countryIsoCode: 'it',
          country: 'Italy',
          region: 'eu',
        },
        {
          countryIsoCode: 'es',
          country: 'Spain',
          region: 'eu',
        },
        {
          countryIsoCode: 'nl',
          country: 'Netherlands',
          region: 'eu',
        },
        {
          countryIsoCode: 'se',
          country: 'Sweden',
          region: 'eu',
        },
        {
          countryIsoCode: 'pl',
          country: 'Poland',
          region: 'eu',
        },
        {
          countryIsoCode: 'jp',
          country: 'Japan',
          region: 'jp',
        },
        {
          countryIsoCode: 'sg',
          country: 'Singapore',
          region: 'jp',
        },
        {
          countryIsoCode: 'in',
          country: 'India',
          region: 'other',
        },
        {
          countryIsoCode: 'tr',
          country: 'Turkey',
          region: 'other',
        },
        {
          countryIsoCode: 'eg',
          country: 'Egypt',
          region: 'other',
        },
        {
          countryIsoCode: 'ae',
          country: 'United Arab Emirates',
          region: 'other',
        },
        {
          countryIsoCode: 'sa',
          country: 'Saudi Arabia',
          region: 'other',
        },
        {
          countryIsoCode: 'au',
          country: 'Australia',
          region: 'au',
        },
      ],
      connectWindow: null,
      regionMarketplaces: [
        {
          code: 'na',
          marketplaces: ['A2EUQ1WTGCTBG2', 'ATVPDKIKX0DER', 'A1AM78C64UM0Y8', 'A2Q3Y263D00KWC'],
        },
        {
          code: 'jp',
          marketplaces: ['A19VAU5U5O7RUS', 'A1VC38T7YXB528'],
        },
        {
          code: 'au',
          marketplaces: ['A39IBJ37TRP1C6'],
        },
        {
          code: 'eu',
          marketplaces: ['A1RKKUPIHCS9HS', 'A1F83G8C2ARO7P', 'A13V1IB3VIYZZH', 'A1805IZSGTT6HS', 'A1PA6795UKMFR9', 'APJ6JRA9NG5V4', 'A2NODRKZP88ZB9', 'A1C3SOZRARQ6R3'],
        },
        {
          code: 'other',
          marketplaces: ['ARBP9OOSHTCHU', 'A33AVAJ2PDY3EV', 'A2VIGQ35RCS4UG', 'A21TJRUUN4KGV'],
        },
      ],
    }
  },
  computed: {
    availableMarketplaces() {
      return this.$store.getters['app/availableMarketplaceIds']
    },
    isMarketplaceAvailable() {
      return this.availableMarketplaces.length > 0
    },
    isRegionConnected() {
      return this.availableMarketplaces.filter(value => this.regionMarketplaces.find(item => item.code === this.region.id).marketplaces.includes(value)).length > 0
    },
    connectUrl() {
      const baseUrl = this.sellerCentralURLs.find(url => url.region === this.region.id).url
      const query = new URLSearchParams({
        application_id: 'amzn1.sp.solution.fe2906d7-0ebe-4810-bb09-245a8139a8c7',
        version: 'beta',
        state: btoa(JSON.stringify({
          subject: this.$store.getters['auth/userId'],
          redirect_uri: `${window.location.origin}/marketplace-connected`,
        })),
      })
      return `${baseUrl}/apps/authorize/consent?${query.toString()}`
    },
    regionFlags() {
      return this.flags.filter(flag => flag.region === this.region.id)
    },
  },
  created() {
    EventBus.$on('ws_marketplace_connected', this.onMarketplaceConnected)
    EventBus.$on('ws_synchronization_refresh', this.onWsSynchronizationRefresh)
  },
  destroyed() {
    EventBus.$off('ws_marketplace_connected')
    EventBus.$off('ws_synchronization_refresh')
  },
  methods: {
    onConnectClick() {
      const width = 700
      const height = 750
      const left = (window.screen.availWidth / 2) - (width / 2)
      const top = (window.screen.availHeight / 2) - (height / 2)
      const features = `location=yes,height=${height},width=${width},scrollbars=yes,status=yes,left=${left},top=${top}screenX=${left},screenY=${top}`
      this.connectWindow = window.open(this.connectUrl, '_blank', features)
    },
    onMarketplaceConnected(data) {
      this.isReloaded = true
      if (data.marketplaces) {
        this.$store.commit('app/UPDATE_AVAILABLE_MARKETPLACES', data.marketplaces)
        this.$store.commit('auth/connected')
      }

      if (this.connectWindow) {
        setTimeout(() => {
          this.connectWindow.close()
          window.location.reload()
        }, 1000)
      } else {
        window.location.reload()
      }
    },
    onWsSynchronizationRefresh() {
      setTimeout(() => {
        if (this.isReloaded) {
          this.isReloaded = false
          return
        }
        window.location.reload()
      }, 2500)
    },
    onRegionChanged(e) {
      this.region = this.regionOptions.find(item => item.id === e.value)
    },
  },
}
</script>

<style scoped>

</style>
