<template>
  <div
    class="tw-relative product-image"
  >
    <div
      v-if="isZoomAvailable"
      v-b-tooltip.hover="title || 'Show Zoomed Image'"
      :title="title || 'Show Zoomed Image'"
      class="tw-absolute tw-top-0 tw-left-0 tw-z-10 zoom-icon"
      :class="attrClass"
      @click="onClick"
    >
      <FeatherIcon
        icon="ZoomInIcon"
        :size="iconSize"
      />
    </div>
    <b-avatar
      rounded
      :size="size"
      :src="src"
      :alt="title"
      :title="title"
    >
      <feather-icon
        v-if="isEmpty(src)"
        :size="iconSize"
        icon="ImageIcon"
      />
    </b-avatar>
  </div>
</template>
<script>
import { BAvatar, VBTooltip } from 'bootstrap-vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'ProductImage',
  components: { BAvatar },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    size: {
      type: String,
      default: '60px',
    },
    iconSize: {
      type: String,
      default: '32',
    },
    src: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    isZoomAvailable: {
      type: Boolean,
      default: true,
    },
    marketplace: {
      type: String,
      default: null,
    },
    marketplaceFlag: {
      type: String,
      default: null,
    },
    asin: {
      type: String,
      required: true,
    },
    attrClass: {
      type: String,
      default: null,
    },
    productUrl: {
      type: String,
      default: null,
    },
  },
  methods: {
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    onClick() {
      EventBus.$emit('on-product-zoom-click', {
        asin: this.asin,
        marketplace: this.marketplace,
        marketplaceFlag: this.marketplaceFlag,
        title: this.title,
        productImage: this.src,
        productUrl: this.productUrl,
      })
      this.$emit('on-product-zoom-click', {
        asin: this.asin,
        marketplace: this.marketplace,
        marketplaceFlag: this.marketplaceFlag,
        title: this.title,
        productImage: this.src,
        productUrl: this.productUrl,
      })
    },
  },
}
</script>

<style scoped lang="scss">
  .zoom-icon {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .product-image {
    &:hover {
      .zoom-icon {
        visibility: visible;
      }
    }
  }
</style>
