<template>
  <div
    class="tw-flex tw-flex-row tw-space-x-2 tw-justify-center tw-items-center"
  >
    <b-spinner
      small
      label="Small Spinner"
      type="grow"
    />
    <b-spinner
      small
      label="Small Spinner"
      type="grow"
    />
    <b-spinner
      small
      label="Small Spinner"
      type="grow"
    />
  </div>
</template>

<script>

import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'SpinnerLoader',
  components: {
    BSpinner,
  },
}
</script>

<style scoped lang="scss">

</style>
