<template>
  <b-form-radio-group
    v-model="aggregationInterval"
    size="sm"
    button-variant="flat-primary"
    :options="aggregationIntervalOptions"
    buttons
    name="radios-btn-default"
    @change="handleChange"
  />
</template>

<script>
import { BFormRadioGroup } from 'bootstrap-vue'

export default {
  name: 'AggregationIntervalSwitch',
  components: {
    BFormRadioGroup,
  },
  props: {
    value: {
      type: String,
      default: 'day',
      validator(value) {
        return ['day', 'week', 'month'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      aggregationInterval: 'day',
      aggregationIntervalOptions: [
        { text: 'Day', value: 'day' },
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
      ],
    }
  },
  mounted() {
    this.aggregationInterval = this.value
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('value-changed', value)
    },
  },
}
</script>

<style scoped>

</style>
